import * as api from '@apis/app-products';
import * as userApi from '@apis/app-user';
import ProductItem from '@components/app/common/ProductItem';
import { Carousel } from '@components/common/Carousel';
import { DeepLinkActions, useDeeplink } from '@hooks/deeplink';
import { useAuthStore } from '@stores/authStore';
import { useLayoutSizeStore } from '@stores/layoutSizeStore';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

export const RecommendedProducts: React.FC<{ productId: string }> = (props) => {
  const { productId } = props;
  const { linkTo } = useDeeplink();
  const { layoutSize } = useLayoutSizeStore();

  const recommendProductsQuery = useQuery({
    queryKey: [`recommend-items-${productId}`],
    queryFn: () => api.getRecommendProducts(productId!),
    enabled: !!productId,
  });

  const recommendProducts = recommendProductsQuery.data?.data || [];
  const itemWidth = layoutSize.width / 2 - 48;

  if (!recommendProducts || recommendProducts.length === 0) {
    return <></>;
  }

  return (
    <div>
      <div style={{ marginTop: '32px', paddingLeft: '16px', paddingRight: '16px' }}>
        <div className="product-detail-title">함께 보면 좋은 클래스</div>
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
          <Carousel
            options={{
              dragFree: true,
              align: 'start',
            }}>
            {recommendProducts.map((item: any, index: number) => {
              return (
                <div
                  key={`class-general-item-${index}`}
                  className={'embla__slide'}
                  style={{ flex: '0 0 auto', width: itemWidth, cursor: 'pointer', marginRight: '12px' }}>
                  <ProductItem
                    onClick={() => {
                      linkTo(
                        {
                          action: DeepLinkActions.GO_TO_PRODUCT,
                          payload: { productId: item._id },
                        },
                        { source: 'recommend-products', index: index, productId: productId },
                      );
                    }}
                    item={item}
                    width={itemWidth}></ProductItem>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export const RecommendedProductsByUserCategories: React.FC = () => {
  const authStore = useAuthStore();
  const user = authStore.user;
  const { linkTo } = useDeeplink();
  const { layoutSize } = useLayoutSizeStore();

  const recommendProductsQuery = useQuery({
    queryKey: [`recommend-items-user-categories-${user?.id}`],
    queryFn: () => userApi.getRecommendProducts(),
    enabled: !!user,
  });

  const recommendProducts = recommendProductsQuery.data?.data || [];
  const itemWidth = layoutSize.width / 2 - 48;

  if (!user) return <></>;

  if (!recommendProducts || recommendProducts.length === 0) {
    return <></>;
  }

  return (
    <div>
      <div style={{ marginTop: '32px', paddingLeft: '16px', paddingRight: '16px' }}>
        <div className="product-detail-title">{`${user?.profile?.name}님의 관심분야 상품`}</div>
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
          <Carousel
            options={{
              dragFree: true,
              align: 'start',
            }}>
            {recommendProducts.map((item: any, index: number) => {
              return (
                <div
                  key={`class-general-item-${index}`}
                  className={'embla__slide'}
                  style={{ flex: '0 0 auto', width: itemWidth, cursor: 'pointer', marginRight: '12px' }}>
                  <ProductItem
                    onClick={() => {
                      linkTo(
                        {
                          action: DeepLinkActions.GO_TO_PRODUCT,
                          payload: { productId: item._id },
                        },
                        { source: 'recommend-products-by-user-categories', index: index },
                      );
                    }}
                    item={item}
                    width={itemWidth}></ProductItem>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export const WeeklyFavoritedProducts: React.FC = () => {
  const authStore = useAuthStore();
  const user = authStore.user;
  const { linkTo } = useDeeplink();
  const { layoutSize } = useLayoutSizeStore();

  const recommendProductsQuery = useQuery({
    queryKey: [`recommend-weekly-favorited-products`],
    queryFn: () => api.getWeeklyFavoritedProducts(),
    enabled: !!user,
  });

  const recommendProducts = recommendProductsQuery.data?.data || [];
  const itemWidth = layoutSize.width / 2 - 48;

  if (!user) return <></>;

  if (!recommendProducts || recommendProducts.length === 0) {
    return <></>;
  }

  return (
    <div>
      <div style={{ marginTop: '32px', paddingLeft: '16px', paddingRight: '16px' }}>
        <div className="product-detail-title">{`한 주간 가장 많이 찜한 상품`}</div>
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
          <Carousel
            options={{
              dragFree: true,
              align: 'start',
            }}>
            {recommendProducts.map((item: any, index: number) => {
              return (
                <div
                  key={`class-general-item-${index}`}
                  className={'embla__slide'}
                  style={{ flex: '0 0 auto', width: itemWidth, cursor: 'pointer', marginRight: '12px' }}>
                  <ProductItem
                    onClick={() => {
                      linkTo(
                        {
                          action: DeepLinkActions.GO_TO_PRODUCT,
                          payload: { productId: item._id },
                        },
                        { source: 'recommend-products-weekly-favorites', index: index },
                      );
                    }}
                    item={item}
                    width={itemWidth}></ProductItem>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
