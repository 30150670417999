import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getUniqueKeyFromUUID } from '@utils/string';
import {
  APPLE_CLIENT_ID,
  APPLE_LOGIN_REDIRECT_URL,
  IMP_ID,
  KAKAO_APP_KEY,
  GA4_ID,
  STAGE,
  WEB_VERSION,
} from '@variables';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';

if (GA4_ID) {
  ReactGA.initialize(GA4_ID);
}

Bugsnag.start({
  apiKey: 'e44dd2fd145a029071c201bf477e5259',
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    return (
      event.errors[0].errorMessage !== 'ResizeObserver loop completed with undelivered notifications.' &&
      event.errors[0].errorMessage !== 'ResizeObserver loop limit exceeded'
    );
  },
  releaseStage: STAGE,
  appVersion: WEB_VERSION,
  enabledReleaseStages: ['dev', 'prod'],
});
BugsnagPerformance.start({ apiKey: 'e44dd2fd145a029071c201bf477e5259' });

const bugsnagPlugin = Bugsnag.getPlugin('react');
const ErrorBoundary = bugsnagPlugin!.createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient(); // 생성

const { IMP, Kakao, AppleID } = window;
if (IMP) {
  IMP.init(IMP_ID); // 가맹점 식별코드
}

Kakao.init(KAKAO_APP_KEY);

AppleID.auth.init({
  clientId: APPLE_CLIENT_ID,
  scope: 'email',
  redirectURI: APPLE_LOGIN_REDIRECT_URL,
  state: getUniqueKeyFromUUID(),
  usePopup: false,
});

root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </React.StrictMode>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
