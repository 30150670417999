import CloseIcon from '@assets/images/app/icon-close-contained.png';
import AppBottomModal from '@components/app/common/AppBottomModal';
import { CommerceFixedOptionItem, ProductSelectedOption } from '@models/commerce';
import { ProductModel, ProductOption } from '@models/product';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useState } from 'react';
import { NumberEdit } from '../common/NumberEdit';
import { OptionSelect } from '../common/OptionSelect';

const Required: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '12px',
        marginLeft: '16px',
        marginTop: '19px',
      }}>
      <div
        style={{
          color: '#424242',
          fontSize: '14px',
          lineHeight: '17px',
        }}>
        필수선택
      </div>
      <div
        style={{
          color: '#ef4747',
          lineHeight: '17px',
          paddingTop: '4px',
        }}>
        *
      </div>
    </div>
  );
};

export const BottomButton: React.FC<{ style?: any; totalFee: number; onClick?: () => void }> = ({
  style,
  totalFee,
  onClick,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        minHeight: '120px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingBottom: '12px',
        borderTop: '1px solid #eff1f4',
        marginTop: 'auto',
        ...style,
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '16px',
          marginTop: '16px',
          width: '100%',
        }}>
        <div
          style={{
            color: '#808387',
            fontSize: '14px',
            lineHeight: '17px',
          }}>
          총 결제 금액
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}>
          <div
            style={{
              color: '#FF3D8F',
              fontSize: '22px',
              lineHeight: '26px',
              fontWeight: 'bold',
            }}>
            {totalFee.toLocaleString()}
          </div>
          <div
            style={{
              color: '#FF3D8F',
              fontSize: '16px',
              lineHeight: '26px',
              fontWeight: 'bold',
            }}>
            원
          </div>
        </div>
      </div>
      <button
        className="link-button"
        style={{
          display: 'flex',
          height: '48px',
          backgroundColor: '#FF3D8F',
          borderRadius: '8px',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
        onClick={() => {
          onClick && onClick();
        }}>
        <div
          style={{
            fontSize: '18px',
            lineHeight: '21px',
            color: '#ffffff',
            fontWeight: 'bold',
          }}>
          구매하기
        </div>
      </button>
    </div>
  );
};

interface ProductOptionViewProps {
  onChangeRequiredOptionSelected?: (value: boolean) => void;
  onChangeTotalFee?: (value: number) => void;
  onChangeSelectedOptions?: (value: ProductSelectedOption) => void;
  productFee: number;
  options: ProductOption[];
  optionType?: string;
  optionLabels?: string[];
  purchaseQuantityOptions?: CommerceFixedOptionItem[];
}

interface SelectedProductOption {
  optionName: string;
  inventory: number;
  fee: number;
  selectedCount: number;
}

interface StepData {
  title: string;
  inventory: number;
  fee?: number;
  index?: number;
}

const ProductOptionView: React.FC<ProductOptionViewProps> = (props) => {
  const {
    optionType,
    optionLabels,
    purchaseQuantityOptions,
    productFee,
    options,
    onChangeTotalFee,
    onChangeSelectedOptions,
    onChangeRequiredOptionSelected,
  } = props;
  const [selectedStep, setSelectedStep] = useState<{ [key: string]: StepData }>({});
  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: SelectedProductOption }>({});
  const [sequentialSteps, setSequentialSteps] = useState<StepData[][]>([]);
  const [directOptions, setDirectOptions] = useState<ProductOption[]>([]);
  const [displayOptionLabels, setDisplayOptionLabels] = useState<string[]>([]);
  const [selectedRequiredFixedCounts, setSelectedRequiredFixedCounts] = useState<{ [key: string]: number }>({});
  const [selectedOptionalFixedCounts, setSelectedOptionalFixedCounts] = useState<{ [key: string]: number }>({});
  const requiredFixedOptions = (purchaseQuantityOptions || []).filter((option) => option.essential);
  const optionalFixedOptions = (purchaseQuantityOptions || []).filter((option) => !option.essential);

  let optionSelectType = optionType;
  let useRequiredPurchaseQuantityOptions = requiredFixedOptions.length > 0;

  if ((sequentialSteps || 0).length > 0 && (directOptions || []).length > 0) {
    optionSelectType = 'sequential_with_direct';
    useRequiredPurchaseQuantityOptions = false;
  } else if ((sequentialSteps || 0).length === 0 && (directOptions || []).length > 0) {
    optionSelectType = 'direct';
    useRequiredPurchaseQuantityOptions = false;
  } else if ((sequentialSteps || 0).length > 0 && (directOptions || []).length === 0) {
    optionSelectType = 'sequential';
  }

  useEffect(() => {
    let totalFee = 0;
    if (!useRequiredPurchaseQuantityOptions) {
      Object.keys(selectedOptions).forEach((key: string) => {
        const item = selectedOptions[key];
        totalFee = totalFee + item.selectedCount * item.fee;
      });
    }

    Object.keys(selectedRequiredFixedCounts).forEach((key: string) => {
      totalFee = totalFee + requiredFixedOptions[Number(key)].fee * selectedRequiredFixedCounts[key];
    });

    Object.keys(selectedOptionalFixedCounts).forEach((key: string) => {
      totalFee = totalFee + optionalFixedOptions[Number(key)].fee * selectedOptionalFixedCounts[key];
    });

    onChangeTotalFee && onChangeTotalFee(totalFee);
    const newSelections = Object.keys(selectedOptions).map((key) => {
      const item = selectedOptions[key];

      return {
        optionName: key,
        optionCount: item.selectedCount,
        optionFee: useRequiredPurchaseQuantityOptions ? 0 : item.fee,
      };
    });

    const selectedRequiredOptions = Object.keys(selectedRequiredFixedCounts).map((key) => {
      const item = selectedRequiredFixedCounts[key];
      return {
        optionName: requiredFixedOptions[Number(key)].title,
        optionCount: item,
        optionFee: requiredFixedOptions[Number(key)].fee,
      };
    });

    const selectedOptionalOptions = Object.keys(selectedOptionalFixedCounts).map((key) => {
      const item = selectedOptionalFixedCounts[key];
      return {
        optionName: optionalFixedOptions[Number(key)].title,
        optionCount: item,
        optionFee: optionalFixedOptions[Number(key)].fee,
      };
    });

    onChangeSelectedOptions &&
      onChangeSelectedOptions({
        options: [...newSelections, ...selectedRequiredOptions, ...selectedOptionalOptions],
        useRequiredPurchaseQuantityOptions,
        optionSelectType,
      });
  }, [selectedOptions, selectedRequiredFixedCounts, selectedOptionalFixedCounts]);

  useEffect(() => {
    const steps: StepData[][] = [];
    const sequentialOptions = options.filter((item) => item.type === 'sequential');
    const depth = (optionLabels || []).length || sequentialOptions[0]?.step.length;

    setDisplayOptionLabels(
      (optionLabels || []).length > 0 ? (optionLabels as string[]) : [...Array(depth)].map(() => '선택하세요.'),
    );

    setDirectOptions(options.filter((item) => item.type === 'direct'));
    if (depth > 0) {
      sequentialOptions.forEach((option) => {
        if (depth === 4) {
          if (!steps[0]) {
            steps[0] = [];
            steps[1] = [];
            steps[2] = [];
            steps[3] = [];
          }

          const stepA = option.step[0];
          const stepB = option.step[1];
          const stepC = option.step[2];
          const stepD = option.step[3];

          const indexStepA = steps[0].findIndex((step) => step.title === stepA);
          const indexStepB = steps[1].findIndex((step) => step.title === stepB);
          const indexStepC = steps[2].findIndex((step) => step.title === stepC);
          const indexStepD = steps[3].findIndex((step) => step.title === stepD);
          if (indexStepA > -1) {
            steps[0][indexStepA] = {
              ...steps[0][indexStepA],
              inventory: steps[0][indexStepA].inventory + (option.inventory || 0),
              index: option.index || 0,
            };
          } else {
            steps[0].push({
              title: stepA,
              inventory: option.inventory || 0,
              fee: option.fee || 0,
              index: option.index || 0,
            });
          }

          if (stepA === selectedStep[0]?.title) {
            if (indexStepB > -1) {
              steps[1][indexStepB] = {
                ...steps[1][indexStepB],
                inventory: steps[1][indexStepB].inventory + (option.inventory || 0),
                index: option.index || 0,
              };
            } else {
              steps[1].push({
                title: stepB,
                inventory: option.inventory || 0,
                fee: option.fee || 0,
                index: option.index || 0,
              });
            }
          }

          if (stepA === selectedStep[0]?.title && stepB === selectedStep[1]?.title) {
            if (indexStepC > -1) {
              steps[2][indexStepC] = {
                ...steps[2][indexStepC],
                inventory: option.inventory || 0,
                index: option.index || 0,
              };
            } else {
              steps[2].push({
                title: stepC,
                inventory: option.inventory || 0,
                fee: option.fee || 0,
                index: option.index || 0,
              });
            }
          }

          if (stepA === selectedStep[0]?.title && stepC === selectedStep[2]?.title) {
            if (indexStepD > -1) {
              steps[3][indexStepD] = {
                ...steps[3][indexStepD],
                inventory: option.inventory || 0,
                index: option.index || 0,
              };
            } else {
              steps[3].push({
                title: stepD,
                inventory: option.inventory || 0,
                fee: option.fee || 0,
                index: option.index || 0,
              });
            }
          }
        }

        if (depth === 3) {
          if (!steps[0]) {
            steps[0] = [];
            steps[1] = [];
            steps[2] = [];
          }

          const stepA = option.step[0];
          const stepB = option.step[1];
          const stepC = option.step[2];

          const indexStepA = steps[0].findIndex((step) => step.title === stepA);
          const indexStepB = steps[1].findIndex((step) => step.title === stepB);
          const indexStepC = steps[2].findIndex((step) => step.title === stepC);
          if (indexStepA > -1) {
            steps[0][indexStepA] = {
              ...steps[0][indexStepA],
              inventory: steps[0][indexStepA].inventory + (option.inventory || 0),
              index: option.index || 0,
            };
          } else {
            steps[0].push({
              title: stepA,
              inventory: option.inventory || 0,
              fee: option.fee || 0,
              index: option.index || 0,
            });
          }

          if (stepA === selectedStep[0]?.title) {
            if (indexStepB > -1) {
              steps[1][indexStepB] = {
                ...steps[1][indexStepB],
                inventory: steps[1][indexStepB].inventory + (option.inventory || 0),
                index: option.index || 0,
              };
            } else {
              steps[1].push({
                title: stepB,
                inventory: option.inventory || 0,
                fee: option.fee || 0,
                index: option.index || 0,
              });
            }
          }

          if (stepA === selectedStep[0]?.title && stepB === selectedStep[1]?.title) {
            if (indexStepC > -1) {
              steps[2][indexStepC] = {
                ...steps[2][indexStepC],
                inventory: option.inventory || 0,
                index: option.index || 0,
              };
            } else {
              steps[2].push({
                title: stepC,
                inventory: option.inventory || 0,
                fee: option.fee || 0,
                index: option.index || 0,
              });
            }
          }
        } else if (depth === 2) {
          if (!steps[0]) {
            steps[0] = [];
            steps[1] = [];
          }

          const stepA = option.step[0];
          const stepB = option.step[1];

          const indexStepA = steps[0].findIndex((step) => step.title === stepA);
          const indexStepB = steps[1].findIndex((step) => step.title === stepB);
          if (indexStepA > -1) {
            steps[0][indexStepA] = {
              ...steps[0][indexStepA],
              inventory: steps[0][indexStepA].inventory + (option.inventory || 0),
              index: option.index || 0,
            };
          } else {
            steps[0].push({
              title: stepA,
              inventory: option.inventory || 0,
              index: option.index || 0,
            });
          }

          if (stepA === selectedStep[0]?.title) {
            if (indexStepB > -1) {
              steps[1][indexStepB] = {
                ...steps[1][indexStepB],
                inventory: option.inventory || 0,
                index: option.index || 0,
              };
            } else {
              steps[1].push({
                title: stepB,
                inventory: option.inventory || 0,
                fee: option.fee || 0,
                index: option.index || 0,
              });
            }
          }
        } else if (depth === 1) {
          if (!steps[0]) {
            steps[0] = [];
          }

          const stepA = option.step[0];

          const indexStepA = steps[0].findIndex((step) => step.title === stepA);
          if (indexStepA > -1) {
            steps[0][indexStepA] = {
              ...steps[0][indexStepA],
              inventory: steps[0][indexStepA].inventory + (option.inventory || 0),
              index: option.index || 0,
            };
          } else {
            steps[0].push({
              title: stepA,
              inventory: option.inventory || 0,
              fee: option.fee || 0,
              index: option.index || 0,
            });
          }
        }
      });
    }

    setSequentialSteps(steps);
  }, [options, selectedStep]);

  const fixedOptionOnlyType = (options || []).length === 0;
  const requiredOptionSelected =
    (Object.keys(selectedRequiredFixedCounts).filter((key) => selectedRequiredFixedCounts[key] > 0).length >
      requiredFixedOptions.length - 1 ||
      requiredFixedOptions.length === 0) &&
    Object.keys(selectedOptions).length > 0;

  useEffect(() => {
    onChangeRequiredOptionSelected && onChangeRequiredOptionSelected(requiredOptionSelected);
  }, [requiredOptionSelected]);

  return (
    <OverlayScrollbarsComponent
      defer
      options={{
        scrollbars: { autoHide: 'scroll' },
        overflow: {
          x: 'hidden',
        },
      }}
      style={{
        backgroundColor: '#ffffff',
        marginTop: 0,
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        width: '100%',
      }}>
      {['sequential', 'sequential_with_direct'].includes(optionSelectType || '') &&
        sequentialSteps &&
        sequentialSteps.length > 0 && (
          <div>
            <Required />
            <div
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flex: 1,
              }}>
              {displayOptionLabels.map((optionPlaceHolder, index) => {
                return (
                  <OptionSelect
                    style={{ marginBottom: '12px' }}
                    key={`option-type-${index}`}
                    label={selectedStep[`${index}`]?.title || optionPlaceHolder}
                    options={(sequentialSteps[index] || []).sort((lv, rv) => {
                      return (lv.index || 0) - (rv.index || 0);
                    })}
                    renderOption={(option, optionIndex) => {
                      const remainCount = option.inventory;
                      return (
                        <div
                          key={`option-type-item-${optionIndex}`}
                          style={{
                            borderBottom: '1px solid #e8eaed',
                            height: '64px',
                            display: 'flex',
                            flexDirection: 'row',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (remainCount <= 0) {
                              return;
                            }
                            setSelectedStep((value) => {
                              const newValue = { ...value };

                              for (let i = index; i < displayOptionLabels.length; i++) {
                                try {
                                  delete newValue[`${i}`];
                                  // eslint-disable-next-line no-empty
                                } catch {}
                              }

                              newValue[`${index}`] = option;

                              if (Object.keys(newValue).length === displayOptionLabels.length) {
                                setSelectedOptions((options) => {
                                  let newOptions = { ...options };
                                  if (useRequiredPurchaseQuantityOptions) {
                                    newOptions = {};
                                  }

                                  const titles = Object.keys(newValue).map((key) => {
                                    return newValue[key].title;
                                  });
                                  const newKey = titles.join(' / ');
                                  if (newOptions[newKey]) {
                                    newOptions[newKey].selectedCount = newOptions[newKey].selectedCount + 1;
                                    if (newOptions[newKey].selectedCount > newOptions[newKey].inventory) {
                                      newOptions[newKey].selectedCount = newOptions[newKey].inventory;
                                    }
                                  } else {
                                    newOptions[newKey] = {
                                      optionName: newKey,
                                      inventory: option.inventory,
                                      fee: (option.fee || 0) + productFee,
                                      selectedCount: 1,
                                    };
                                  }

                                  return newOptions;
                                });
                              }
                              return newValue;
                            });
                          }}>
                          <div
                            style={{
                              color: remainCount > 0 ? '#424242' : '#b9bbbe',
                              fontSize: '14px',
                              lineHeight: '19px',
                            }}>
                            {option.title}
                          </div>
                        </div>
                      );
                    }}></OptionSelect>
                );
              })}
            </div>
          </div>
        )}

      {['direct', 'sequential_with_direct'].includes(optionSelectType || '') &&
        directOptions &&
        directOptions.length > 0 && (
          <div
            style={{
              marginLeft: '16px',
              marginTop: '12px',
            }}>
            {optionSelectType === 'sequential_with_direct' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginBottom: '12px',
                }}>
                <div
                  style={{
                    color: '#424242',
                    fontSize: '14px',
                    lineHeight: '17px',
                  }}>
                  추가선택
                </div>
              </div>
            )}
            {optionSelectType === 'direct' && <Required />}
            <OptionSelect
              style={{ marginBottom: '12px' }}
              label={'상품을 추가하세요.'}
              options={directOptions}
              renderOption={(option, optionIndex) => {
                return (
                  <div
                    key={`option-type-sub-item-${optionIndex}`}
                    style={{
                      borderBottom: '1px solid #e8eaed',
                      height: '64px',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setSelectedOptions((selections) => {
                        const newSelections = { ...selections };
                        const newKey = option.step[0];
                        if (newSelections[newKey]) {
                          let newCount = newSelections[newKey].selectedCount + 1;
                          if (newCount > newSelections[newKey].inventory) {
                            newCount = newSelections[newKey].inventory;
                          }
                          newSelections[newKey].selectedCount = newCount;
                        } else {
                          newSelections[newKey] = {
                            optionName: option.step[0],
                            fee: option.fee || 0,
                            inventory: option.inventory || 0,
                            selectedCount: 1,
                          };
                        }
                        return newSelections;
                      });
                    }}>
                    <div
                      style={{
                        color: (option.inventory || 0) > 0 ? '#424242' : '#b9bbbe',
                        fontSize: '14px',
                        lineHeight: '19px',
                      }}>
                      {option.step[0]}
                    </div>
                  </div>
                );
              }}></OptionSelect>
          </div>
        )}
      {!useRequiredPurchaseQuantityOptions && Object.keys(selectedOptions).length > 0 && (
        <div>
          <div
            style={{
              height: 1,
              backgroundColor: '#eff1f4',
              margin: '16px 20px',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginBottom: '8px',
              marginLeft: '16px',
            }}>
            <div
              style={{
                color: '#424242',
                fontSize: '14px',
                lineHeight: '17px',
              }}>
              수량선택
            </div>
          </div>
          {Object.keys(selectedOptions).map((key, index) => {
            const item = selectedOptions[key];

            return (
              <div
                key={`selected-product-${index}`}
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  backgroundColor: '#f8f9fc',
                  borderRadius: '8px',
                  marginBottom: '12px',
                  marginLeft: '16px',
                  marginRight: '16px',
                  border: '1px solid #e8eaed',
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    marginBottom: '13px',
                    marginTop: '12px',
                  }}>
                  <div
                    style={{
                      color: '#424242',
                      fontSize: '16px',
                      lineHeight: '19px',
                    }}>
                    {item.optionName}
                  </div>
                  <button
                    className="link-button"
                    onClick={() => {
                      setSelectedOptions((selections) => {
                        const newSelections = { ...selections };
                        delete newSelections[key];
                        return newSelections;
                      });
                    }}>
                    <img
                      src={CloseIcon}
                      style={{
                        width: '24px',
                        height: '24px',
                        marginLeft: '21px',
                      }}
                    />
                  </button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '13px',
                  }}>
                  <NumberEdit
                    min={1}
                    max={item.inventory}
                    value={item.selectedCount}
                    onClickMinus={(value) => {
                      setSelectedOptions((selections) => {
                        const newSelections = { ...selections };
                        newSelections[key].selectedCount = value;

                        return newSelections;
                      });
                    }}
                    onClickPlus={(value) => {
                      setSelectedOptions((selections) => {
                        const newSelections = { ...selections };
                        newSelections[key].selectedCount = value;

                        return newSelections;
                      });
                    }}
                    onChangeValue={(value) => {
                      setSelectedOptions((selections) => {
                        const newSelections = { ...selections };
                        newSelections[key].selectedCount = value;
                        return newSelections;
                      });
                    }}
                  />
                  <div
                    style={{
                      color: '#242424',
                      fontSize: '16px',
                      lineHeight: '17px',
                      fontWeight: 'bold',
                    }}>
                    {item.fee.toLocaleString()}원
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {Object.keys(selectedOptions).length > 0 && (
        <div
          style={{
            height: 1,
            backgroundColor: '#eff1f4',
            marginTop: '4px',
            marginBottom: '16px',
            marginLeft: '16px',
          }}
        />
      )}
      <div>
        {useRequiredPurchaseQuantityOptions && (Object.keys(selectedOptions).length > 0 || fixedOptionOnlyType) && (
          <div>
            <Required />
            {requiredFixedOptions.map((item, index) => {
              const selects = Object.keys(selectedOptions).map((key) => {
                return selectedOptions[key];
              });
              const remainCount = selects[0]?.inventory || 0;

              return (
                <div
                  key={`fixed-option-${index}`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    height: '90px',
                    backgroundColor: '#f8f9fc',
                    border: '1px solid #e8eaed',
                    borderRadius: '8px',
                    marginBottom: '8px',
                    marginLeft: '16px',
                    marginRight: '16px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      marginBottom: '13px',
                      marginTop: '11px',
                    }}>
                    <div
                      style={{
                        color: '#424242',
                        fontSize: '16px',
                        lineHeight: '19px',
                      }}>
                      {item.title}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '13px',
                      width: '100%',
                    }}>
                    <div
                      style={{
                        color: '#242424',
                        fontSize: '16px',
                        lineHeight: '17px',
                        fontWeight: 'bold',
                      }}>
                      {item.fee.toLocaleString()}원
                    </div>
                    <NumberEdit
                      min={0}
                      max={remainCount}
                      value={selectedRequiredFixedCounts[`${index}`] || 0}
                      onClickMinus={(value) => {
                        setSelectedRequiredFixedCounts((prev) => {
                          const newValue = { ...prev };
                          newValue[`${index}`] = value;
                          return newValue;
                        });
                      }}
                      onClickPlus={(value) => {
                        setSelectedRequiredFixedCounts((prev) => {
                          const newValue = { ...prev };
                          newValue[`${index}`] = value;
                          return newValue;
                        });
                      }}
                      onChangeValue={(value) => {
                        setSelectedRequiredFixedCounts((prev) => {
                          const newValue = { ...prev };
                          newValue[`${index}`] = value;
                          return newValue;
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {optionalFixedOptions.length > 0 && (Object.keys(selectedOptions).length > 0 || fixedOptionOnlyType) && (
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginBottom: '12px',
                marginLeft: '16px',
                marginTop: '8px',
              }}>
              <div
                style={{
                  color: '#424242',
                  fontSize: '14px',
                  lineHeight: '17px',
                }}>
                추가선택
              </div>
            </div>
            {optionalFixedOptions.map((item, index) => {
              if (requiredOptionSelected) {
                const selects = Object.keys(selectedOptions).map((key) => {
                  return selectedOptions[key];
                });
                const remainCount = selects[0]?.inventory || 0;
                return (
                  <div
                    key={`fixed-option-${index}`}
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      height: '90px',
                      backgroundColor: '#f8f9fc',
                      border: '1px solid #e8eaed',
                      borderRadius: '8px',
                      marginBottom: '8px',
                      marginLeft: '16px',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        marginBottom: '13px',
                        marginTop: '11px',
                      }}>
                      <div
                        style={{
                          color: '#424242',
                          fontSize: '16px',
                          lineHeight: '19px',
                        }}>
                        {item.title}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '13px',
                      }}>
                      <div
                        style={{
                          color: '#242424',
                          fontSize: '16px',
                          lineHeight: '17px',
                          fontWeight: 'bold',
                        }}>
                        {item.fee.toLocaleString()}원
                      </div>
                      <NumberEdit
                        min={0}
                        max={remainCount}
                        value={selectedOptionalFixedCounts[`${index}`] || 0}
                        onClickMinus={(value) => {
                          setSelectedOptionalFixedCounts((prev) => {
                            const newValue = { ...prev };
                            if (value < 1) {
                              delete newValue[`${index}`];
                            } else {
                              newValue[`${index}`] = value;
                            }
                            return newValue;
                          });
                        }}
                        onClickPlus={(value) => {
                          setSelectedOptionalFixedCounts((prev) => {
                            const newValue = { ...prev };
                            if (value < 1) {
                              delete newValue[`${index}`];
                            } else {
                              newValue[`${index}`] = value;
                            }
                            return newValue;
                          });
                        }}
                        onChangeValue={(value) => {
                          setSelectedOptionalFixedCounts((prev) => {
                            const newValue = { ...prev };
                            if (value < 1) {
                              delete newValue[`${index}`];
                            } else {
                              newValue[`${index}`] = value;
                            }
                            return newValue;
                          });
                        }}
                      />
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    key={`fixed-option-${index}`}
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      height: '90px',
                      backgroundColor: '#f8f9fc',
                      border: '1px solid #e8eaed',
                      borderRadius: '8px',
                      marginBottom: '8px',
                      marginLeft: '16px',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        marginBottom: '13px',
                        marginTop: '11px',
                      }}>
                      <div
                        style={{
                          color: '#b9bbbe',
                          fontSize: '16px',
                          lineHeight: '19px',
                        }}>
                        {item.title}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '13px',
                      }}>
                      <div
                        style={{
                          color: '#b9bbbe',
                          fontSize: '16px',
                          lineHeight: '17px',
                          fontWeight: 'bold',
                        }}>
                        {item.fee.toLocaleString()}원
                      </div>
                      <NumberEdit disabled />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
    </OverlayScrollbarsComponent>
  );
};

interface CommerceOptionModalProps {
  product?: ProductModel;
  onCancel?: () => void;
  onConfirm?: () => void;
  onChangeTotalFee: (value: number) => void;
  onChangeSelectedOptions?: (value: ProductSelectedOption) => void;
  open?: boolean;
}

export const CommerceOptionModal: React.FC<CommerceOptionModalProps> = (props) => {
  const { open, product } = props;
  const [totalFee, setTotalFee] = useState<number>(0);
  const [requiredOptionSelected, setRequiredOptionSelected] = useState<boolean>(false);
  const optionType = product?.optionType || 'direct';

  return (
    <AppBottomModal
      key={optionType}
      title={'옵션 선택'}
      open={open}
      height={0}
      hideLeftNaviButton
      onRightNaviButtonClick={() => {
        props.onCancel && props.onCancel();
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          position: 'relative',
        }}>
        <ProductOptionView
          productFee={product?.fee || 0}
          options={product?.productOptions || []}
          optionType={product?.optionType}
          optionLabels={product?.optionLabels}
          purchaseQuantityOptions={product?.purchaseQuantityOptions}
          onChangeTotalFee={(value) => {
            setTotalFee(value);
            props.onChangeTotalFee && props.onChangeTotalFee(value);
          }}
          onChangeSelectedOptions={props.onChangeSelectedOptions}
          onChangeRequiredOptionSelected={(value) => {
            setRequiredOptionSelected(value);
          }}
        />
        <div style={{ alignSelf: 'flex-end', width: '100%' }}>
          {requiredOptionSelected && totalFee > 0 && (
            <BottomButton
              totalFee={totalFee}
              onClick={() => {
                props.onConfirm && props.onConfirm();
              }}
            />
          )}
        </div>
      </div>
    </AppBottomModal>
  );
};
